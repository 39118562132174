import { combineReducers } from "redux"

import list from "./list/slice"
import remove from "./remove/slice"
import deactivate from "./deactivate/slice"
import activate from "./activate/slice"
import generate from "./generate/slice"

const userAccountsReducer = combineReducers({
  list,
  remove,
  deactivate,
  activate,
  generate
})

export default userAccountsReducer
