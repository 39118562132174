import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import removeSaga from "./remove/saga"
import deactivateSaga from "./deactivate/saga"
import activateSaga from "./activate/saga"
import generateSaga from "./generate/saga"

function* userAccountsSaga() {
  yield all([listSaga(), removeSaga(), deactivateSaga(), activateSaga(), generateSaga()])
}

export default userAccountsSaga
